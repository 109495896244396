import * as Firebase from 'firebase'
import getFirebase from './firebase'
import { size } from 'lodash'
import { LISTING_STATUS } from 'constants/listingConstants'
import { calculateWeightByDimensions, UNITS } from 'constants/utils'
const firebase = getFirebase()

export const createListing = (
  user,
  designerInfo,
  modelDescription,
  zipCode,
  country,
  status = LISTING_STATUS.SUBMITTED,
  hasEstimationForAllParts = false,
  weightFromSlicer = null
) => {
  return new Promise((resolve, reject) => {
    if (firebase) {
      const {
        category,
        depth,
        description,
        estimatedMaterial,
        estimatedMaterialPerFile = [],
        estimatedPrintTime,
        height,
        images,
        thumbnail,
        isSupportRequired = false,
        materialColor,
        material,
        modelId,
        name,
        printNotes,
        quantity,
        storeId = '',
        width,
      } = modelDescription
      const {
        uid: userId,
        first_name: firstName = '',
        last_name: lastName = '',
        photo_url: photoUrl = '',
      } = user

      const weightByDimensions = calculateWeightByDimensions(
        material,
        depth,
        height,
        width
      )

      const weightValue =
        hasEstimationForAllParts && weightFromSlicer
          ? weightFromSlicer
          : weightByDimensions

      const weight = {
        unit: UNITS.GRAM,
        value: weightValue,
      }

      firebase
        .firestore()
        .collection('listings')
        .add({
          owned_by: userId,
          category,
          description,
          designer_info: {
            first_name: designerInfo.first_name,
            last_name: designerInfo.last_name,
            uid: designerInfo.uid,
          },
          estimated_material: estimatedMaterial,
          estimated_material_per_file: estimatedMaterialPerFile,
          estimated_print_time: estimatedPrintTime,
          images,
          thumbnail,
          is_support_required: isSupportRequired,
          is_deleted: false,
          model: modelId,
          model_name: name,
          zip_code: zipCode,
          country,
          status: status,
          storeId: storeId,
          submission_date: Firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
          tech_specs: {
            color: materialColor,
            height,
            length: depth,
            material,
            print_notes: printNotes,
            quantity,
            weight,
            width,
          },
          user_info: {
            first_name: firstName,
            last_name: lastName,
            photo_url: photoUrl,
          },
        })
        .then(docRef => {
          resolve(docRef.id)
        })
        .catch(error => {
          console.log(error.message)
          reject(error)
        })
    }
  })
}

export const getListing = (userUid, limit = 10, startAfter, statusIn = []) => {
  return new Promise((resolve, reject) => {
    let query = firebase
      .firestore()
      .collection('listings')
      .orderBy('submission_date', 'desc')
      .where('is_deleted', '==', false)
      .limit(limit)
    if (startAfter) {
      query = query.startAfter(startAfter)
    }
    if (userUid) {
      query = query.where('owned_by', '==', userUid)
    }
    if (size(statusIn)) {
      query = query.where('status', 'in', statusIn)
    }
    query
      .get()
      .then(querySnapshot => {
        const lastElement = querySnapshot.docs[querySnapshot.docs.length - 1]
        const listings = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }))
        resolve({ listings, lastElement })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getListingById = listingId => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('listings')
      .doc(listingId)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve({ id: doc.id, ...doc.data() })
        } else {
          reject({})
        }
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const getListingsByIds = (listingIds = []) => {
  return new Promise((resolve, reject) => {
    const promises = listingIds.map(id => {
      return getListingById(id)
    })
    Promise.all(promises)
      .then(listings => {
        resolve({ listings })
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateListing = (listingId, fieldsToUpdate) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('listings')
      .doc(listingId)
      .update({
        ...fieldsToUpdate,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(listingId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateListingStatus = (listingId, status) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('listings')
      .doc(listingId)
      .update({
        status: status,
        updated_at: Firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(() => {
        resolve(listingId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateProductionFiles = (listingId, productionFiles) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('listings')
      .doc(listingId)
      .update({
        production_files: {
          images: productionFiles.images,
          notes: productionFiles.notes,
        },
      })
      .then(() => {
        resolve(listingId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}

export const updateShippingTracking = (listingId, shipmentInfo) => {
  return new Promise((resolve, reject) => {
    firebase
      .firestore()
      .collection('listings')
      .doc(listingId)
      .update({
        shipping_tracking: {
          carrier_code: shipmentInfo.carrierCode,
          carrier_id: shipmentInfo.carrierId,
          label_id: shipmentInfo.labelId,
          package_id: shipmentInfo.packageId,
          shipment_id: shipmentInfo.shipmentId,
          shipper: shipmentInfo.shipper,
          shipping_label_link: shipmentInfo.shippingLabelLink,
          status: shipmentInfo.status,
          tracking_id: shipmentInfo.trackingId,
          tracking_status: shipmentInfo.trackingStatus,
        },
      })
      .then(() => {
        resolve(listingId)
      })
      .catch(error => {
        console.log(error.message)
        reject(error)
      })
  })
}
